
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "admin-legacy-policies-list",
  components: {},
  setup() {
    const {
      isEmptyArray,
      displayDatatable,
      useReusableNavigation,
      reinitialization,
    } = ResusableFunctions();
    const store = useStore();

    const policies = ref([
      {
        businessTypeShortCode: "",
        policySummary: {
          client: "",
          year: "",
          type: "",
          number: "",
          reference: "",
          premium: "",
          sumInsured: "",
          status: "",
          startDate: "",
          expiryDate: "",
          publicId: "",
        },
        publicId: "",
      },
    ]);

    const getPolicies = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.LEGACY_POLICY)
          .then(({ data }) => {
            //Assign data to form fields
            policies.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const redirect = (value: string) => {
      let route = "";

      switch (value) {
        case "HOM-HOU":
          route = "/policy/policy-information/house-insurance/owned-building/";
          break;
        case "MOT":
          route = "/admin/policy/policy-information/motor-insurance/";
          break;
        default:
          route = "";
      }

      return route;
    };

    const editPolicyRoute = (value, details) => {
      let route = "";

      switch (value) {
        case "HOM-HOU":
          route = "/admin/policy-migration/house-insurance/edit/";
          break;
        case "MOT":
          route = "/admin/policy-migration/motor-insurance/edit/";
          break;
        default:
          route = "";
      }

      store.commit("DynamicDetails/clearDetails");
      store.commit("DynamicDetails/setDetails", details);

      return route;
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Existing Policies", ["Policy Migration"]);
      getPolicies();
      if (!isEmptyArray(policies.value)) {
        displayDatatable(["basicExample"]);
      }
    });

    return {
      reinitialization,
      useReusableNavigation,
      redirect,
      policies,
      editPolicyRoute,
    };
  },
  created() {
    //Set page title
    document.title =
      "Administrator: Existing Policies List | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
});
